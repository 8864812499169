import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Spacer from '../../components/Spacer'
import Layout from '../../layout/Layout'

import styles from './404.module.scss'

interface Props {
  pageContext: PageContext
}

interface QueryProps {
  fileName: {
    childImageSharp: {
      fluid: FluidObject,
    }
  }
}

export const NotFound: React.FunctionComponent<Props> = ({ pageContext }) => {
  const data = useStaticQuery<QueryProps>(vortexQuery)

  return (
    <Layout pageContext={pageContext}>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentContainer}>
            <h1 className={`${styles.heading} dark-bg`}>{pageContext.headline}</h1>
            <h5 className={`${styles.subheading} pink`}>{pageContext.subheadline}</h5>
            <Link to='/' className={`btn btn--lg btn--green ${styles.takeMeHome}`}>
              Take me home
            </Link>
            <img src='/images/illustrations/planet1.svg' alt='Moon' className={styles.planetMoon}/>
            <img
              src='/images/illustrations/planet2.svg'
              alt='Core Planet'
              className={styles.planetCore}
            />
            <img
              src='/images/illustrations/planet3.svg'
              alt='Planet'
              className={styles.planetBaloon}
            />
            <img
              src='/images/illustrations/planet4.svg'
              alt='Jupiter'
              className={styles.planetJupiter}
            />
          </div>
        </div>
        <Img
          fluid={data.fileName.childImageSharp.fluid}
          alt='Vortex'
          className={styles.vortex}
        />
      </div>
      {/* add spacer because of the hero -5px bottom margin which causes footer to bleed into it */}
      <Spacer zIndex={1}/>
    </Layout>
  )
}

const vortexQuery = graphql`
  query {
    fileName: file(relativePath: { eq: "images/vortex.png" }) {
      childImageSharp {
        fluid {
          ... GatsbyImageSharpFluid
        }
      }
    }
  }
`
