import React from 'react'

interface Props {
  height?: string
  backgroundColor?: string
  zIndex?: number
}

export const Spacer: React.FunctionComponent<Props> = ({
  height = '5px',
  backgroundColor = 'white',
  zIndex = 2,
}) => (
  <div style={{ position: 'relative', height, backgroundColor, zIndex }}/>
)
