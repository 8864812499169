import React from 'react'

import { SeoType } from '../enums'
import NotFound from '../page-components/404'

const NotFoundPage: React.FC = () => <NotFound pageContext={pageContext}/>

export default NotFoundPage

const pageContext: PageContext = {
  id: '404',
  headline: "That's not the Hole You Were Looking for!",
  subheadline: 'Oooops!',
  sections: [],
  seo: {
    title: 'Page not found',
    description: "That's not the Hole You Were Looking for!",
    type: SeoType.Website,
    image: {
      id: 'none',
      url: '',
      width: 0,
      height: 0,
    },
    keywords: ['page not found'],
  },
}
